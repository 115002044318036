import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/app/homepage/homepage-puzzle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/banner-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/fun-series-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/series-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/workspace/src/components/ui/tabs.tsx");
